import { Layout } from 'components/common'
import { usePartnerData } from 'hooks/usePartnerData'
import React from 'react'

const ThanksMessage = ({ sendTo, resetForm }) => {
  const { shortName } = usePartnerData()

  return (
    <div className="thanks-message">
      <h2>Thank You, We'll Be in Touch</h2>
      <p className="featured">Explore Next Steps at {shortName}</p>
      <p>
        We can't wait to tell you more about your program of interest. You should expect to hear from us soon. Until
        then, you can explore our website for information regarding admission requirements, courses, faculty, and more.
        Take the next step in your journey with us.
      </p>
      <a href={sendTo || 'https://www.morgan.edu'} className="button">
        {sendTo ? 'See Program Details' : 'Explore Morgan'}
      </a>
    </div>
  )
}

const Thanks = () => (
  <Layout>
    <main>
      <div id="thanks-page">
        <seoTitle />
        <ThanksMessage />
      </div>
    </main>
  </Layout>
)

export default Thanks
